import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useMetrics } from "../../page-components/projects/metrics"
import {
  LocationTemplateProps,
  Template,
} from "../../page-components/projects/template"
import { imgFinder } from "../../page-components/projects/5-floor-plan"
import ProjectDetails from "../../images/projects/BF.yml"

const BloomField1a1b = () => {
  const { logo, siteDevImg, floorPlans } = useStaticQuery(graphql`
    query($floorPlan: String = "/images/projects/bf-1a-1b/04 FLOOR PLANS/") {
      logo: file(relativePath: { eq: "BLOOMFIELD 1A _ 1B LOGO.png" }) {
        ...ImgL
      }
      siteDevImg: file(
        relativePath: { eq: "projects/bf-1a-1b/BF 1A _ 1B SDP.png" }
      ) {
        ...SiteDevImg
      }
      ...FloorPlans
    }
  `)
  const fpImg = imgFinder(floorPlans)
  const metrics = useMetrics([
    { metric: "TownhouseUnits", num: "208" },
    { metric: "TypesofUnits", num: "2" },
    { metric: "TypicalLotArea", num: "35 - 40" },
    { metric: "TypicalFloorArea", num: "23.30 - 30" },
  ])
  const props: LocationTemplateProps = {
    title: "Bloomfield 1A & 1B",
    logo,
    location: "Brgy. Bical, Mabalacat City, Pampanga",
    metrics,
    carouselImgs: [
      require("../../images/projects/bf-1a-1b/01 BF 1A _ 1B GATE.jpg"),
      require("../../images/projects/bf-1a-1b/02 BF 1A _ 1B.jpg"),
      require("../../images/projects/bf-1a-1b/03 BF 1A _ 1B.jpg"),
    ],
    siteDev: {
      img: siteDevImg,
      txt:
        "Bloomfield 1A & 1B is the first phase of the Bloomfield Development. It is strategically planned for easy maneuvering and wayfinding around the community. Bloomfield 1A has a total 197 units while 1B has a total of 101 units.",
    },
    floorPlans: {
      description: `Bloomfield 1A & 1B has two (2) types of units to offer. (1) Olena - stands at two-storeys with a typical lot area of 40sq.m and floor area of 38sq.m; and (2) Cynara - a bungalow type unit with a typical lot area of 35sq.m and floor area of 35sq.m.

        Our units give you the freedom and the power to create your home the way you want to. ** All Floor Plans below are for suggested layout only.`,
      items: [
        [
          {
            name: "Olena",
            fpImg: fpImg("BF OLENA FP"),
            pImg: fpImg("BF OLENA PERSP"),
          },
          {
            name: "Cynaraa",
            fpImg: fpImg("BF CYNARA FP"),
            pImg: fpImg("FP CYNARA PERSP"),
          },
        ],
      ],
    },
    projDetails: {
      ...ProjectDetails,
      name: "Bloomfield 1A & 1B",
      description: `Bloomfield is affordable living strategically located in the city of Mabalacat. Surrounded by several neighboring commercial institutions, schools, and facilities, Bloomfield offers the convenience and comfort of city living.

      It’s the ideal haven for young couples, starting families, accomplished young professionals, retirees and OFW’s. Live peacefully in a serene and intimate community as the development is surrounded by lots of generous green open space and amenities.`
    }
  }
  return <Template {...props} />
}

export default BloomField1a1b
